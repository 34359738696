<template>
  <p :id="id" :class="type" :title="mytitle"><slot /></p>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "p1",
      //required: true,
    },
    mytitle: {
      type: String,
    },
    id: {
      type: String,
    },
  },
};
</script>
