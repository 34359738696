<template>
  <h1 v-if="type.includes('h1') || type.substr(0, 2) === 'h2'" :class="type">
    <slot />
  </h1>
  <h2 v-else-if="type.includes('h2')" :class="type"><slot /></h2>
  <h3 v-else-if="type.includes('h3')" :class="type"><slot /></h3>
  <h4 v-else-if="type.includes('h4')" :class="type"><slot /></h4>
  <h5 v-else-if="type.includes('h5')" :class="type"><slot /></h5>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "h1",
      required: true,
    },
  },
};
</script>
