<template>
  <v-row>
    <v-dialog
      v-if="dialog"
      id="confirmation-modal-0cb7d8f8"
      v-model="dialog"
      max-width="600px"
      min-wi
    >
      <v-card
        style="box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05)"
        id="confirmation-card-1b3bbef8"
      >
        <v-container style="padding-bottom: 36px; padding-top: 40px">
          <v-row justify="center" align="center">
            <v-col cols="auto">
              <Images
                name="https://res.cloudinary.com/dyf7aejih/image/upload/v1617703054/images/confirmations_aniog4.svg"
              />
            </v-col>
            <div style="width: 100%"></div>
            <v-col cols="auto" style="text-align: center">
              <Heading
                v-if="header"
                type="h3 MainDark"
                style="padding-top: 10px; padding-bottom: 14px"
                >{{ header }}</Heading
              >
              <Texting
                :type="text1_params ? text1_params : 'p3 grey6'"
                v-html="text1"
                :style="{ opacity: text1_params ? '.8' : '' }"
              ></Texting>
              <Texting type="p3 grey6">{{ text2 }}</Texting>
            </v-col>
          </v-row>
          <v-row justify="center" style="padding-top: 30px">
            <v-col cols="auto">
              <Button
                id="confirmation-done-button-3d4eee34"
                type="primary"
                size="normal"
                texting="p3bold mywhite"
                :handleClick="hide"
                >done</Button
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Button from "./Button.vue";
import Texting from "./Texting";
import Images from "./MyImages";
import Heading from "@/components/Heading";
export default {
  components: { Heading, Images, Texting, Button },
  props: {
    text1: {
      type: String,
    },
    text2: {
      type: String,
    },
    active: {
      type: String,
    },
    header: String,
    text1_params: String,
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    dialog: function (val) {
      if (!val) {
        //router.push({ path: "/" });
        this.$router.replace({
          query: null,
        });
      }
    },
  },
  mounted() {
    if (this.active) {
      this.dialog = true;
    }
  },
  methods: {
    show() {
      this.dialog = true;
    },
    hide() {
      this.$emit("done");
      this.dialog = false;
    },
  },
};
</script>
