export default new (class {
  myAnalyticsEvents(vm, category, action, label) {
    //vm.$ga.event(category, action);
    vm.$gtag.event(action, {
      event_category: category,
      event_label: label ? label : "",
    });
    vm.$mixpanel.track(category, {
      action: action,
    });
  }
  showErrorMessage(vm, error, message = "Oops, something went wrong") {
    if (error.response && error.response.data) {
      message = error.response.data.message;
    }
    vm.$toasted.error(message, {
      duration: 5000,
      className: "toast-error",
    });
  }
})();
