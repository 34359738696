<template>
  <div style="height: 100px" :class="{ 'footer-home': !user }">
    <v-footer
      v-if="!user"
      style="padding: 0; background-color: #ffffff"
      absolute
    >
      <v-container class="pa-0" fluid>
        <v-row justify="center" align="center">
          <v-col
            lg="6"
            md="7"
            sm="10"
            xs="10"
            style="padding-bottom: 16px; padding-top: 16px"
          >
            <v-row justify-md="space-between" justify="center" align="center">
              <v-col cols="auto" class="social-icons-mobile">
                <v-row justify="end" align="center">
                  <v-col cols="auto" style="height: 56px">
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fperkfection.com&display=page"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Images
                        id="footer-mobile-fb-icon-ed3b0e52"
                        name="https://res.cloudinary.com/dyf7aejih/image/upload/v1619595158/images/fb_icon_zeamzk.svg"
                        @click.native="fbClick"
                        class="pointer logo"
                      />
                    </a>
                  </v-col>
                  <v-col
                    cols="auto"
                    style="padding-left: 0; padding-right: 0; height: 56px"
                  >
                    <a
                      :href="'https://twitter.com/intent/tweet?url=' + getUrl()"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Images
                        id="footer-mobile-twitter-icon-0094c4fc"
                        name="https://res.cloudinary.com/dyf7aejih/image/upload/v1619595158/images/twitter_icon_wgbyiz.svg"
                        @click.native="twClick"
                        class="pointer logo"
                      />
                    </a>
                  </v-col>
                  <v-col cols="auto" style="height: 56px">
                    <a
                      href="https://www.linkedin.com/shareArticle?mini=true&url=https://perkfection.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Images
                        id="footer-mobile-linkedin-icon-1de94ec4"
                        name="https://res.cloudinary.com/dyf7aejih/image/upload/v1619595158/images/in_icon_ahzuzv.svg"
                        @click.native="liClick"
                        class="pointer logo"
                      />
                    </a>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="auto"
                class="footer-texts"
                style="padding-bottom: 2px"
              >
                <v-row justify-lg="start" justify="center" align="center">
                  <v-col
                    cols="12"
                    sm="auto"
                    order-sm="first"
                    order="last"
                    style="padding-top: 8px"
                  >
                    <Texting type="p1 MainDark" style="text-align: center"
                      >&copy; {{ new Date().getFullYear() }} Perkfection
                      inc.</Texting
                    >
                  </v-col>
                  <v-col
                    cols="auto"
                    class="d-none d-sm-flex"
                    style="padding: 0"
                  >
                    <Images name="vertical_line.svg" />
                  </v-col>
                  <v-col cols="auto" style="padding-top: 5px">
                    <router-link
                      to="/Terms-and-Conditions"
                      @click.native="termsClick"
                      class="text_decor"
                    >
                      <Texting
                        id="footer-terms-43d78de4"
                        type="p1 grey10"
                        class="animate_back"
                        >Terms of Service</Texting
                      >
                    </router-link>
                  </v-col>
                  <div style="width: 100%" class="d-flex d-sm-none"></div>
                  <v-col
                    cols="auto"
                    class="d-none d-sm-flex"
                    style="padding: 0"
                  >
                    <Images name="vertical_line.svg" />
                  </v-col>
                  <v-col cols="auto" style="padding-top: 5px">
                    <router-link
                      to="/Privacy-Policy"
                      @click.native="privacyClick"
                      class="text_decor"
                    >
                      <Texting
                        id="footer-privacy-648a15f2"
                        type="p1 grey10"
                        class="pointer animate_back"
                        >Privacy</Texting
                      >
                    </router-link>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="auto" class="social-icons">
                <v-row justify="end" align="center">
                  <v-col cols="auto" style="height: 56px">
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fperkfection.com&display=page"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Images
                        id="footer-fb-icon-ec37184c"
                        name="https://res.cloudinary.com/dyf7aejih/image/upload/v1619595158/images/fb_icon_zeamzk.svg"
                        @click.native="fbClick"
                        class="pointer logo"
                      />
                    </a>
                  </v-col>
                  <v-col
                    cols="auto"
                    style="padding-left: 0; padding-right: 0; height: 56px"
                  >
                    <a
                      :href="'https://twitter.com/intent/tweet?url=' + getUrl()"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Images
                        id="footer-twitter-icon-f3a35dc0"
                        name="https://res.cloudinary.com/dyf7aejih/image/upload/v1619595158/images/twitter_icon_wgbyiz.svg"
                        @click.native="twClick"
                        class="pointer logo"
                      />
                    </a>
                  </v-col>
                  <v-col cols="auto" style="height: 56px">
                    <a
                      href="https://www.linkedin.com/shareArticle?mini=true&url=https://perkfection.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Images
                        id="footer-linkedin-icon-fa1939d6"
                        name="https://res.cloudinary.com/dyf7aejih/image/upload/v1619595158/images/in_icon_ahzuzv.svg"
                        @click.native="liClick"
                        class="pointer logo"
                      />
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <v-footer v-else class="user-footer" absolute>
      <v-container class="pa-0 ma-0" fluid>
        <v-row justify="center" align="center">
          <v-col
            cols="auto"
            style="text-align: center; padding-top: 35px; padding-bottom: 35px"
          >
            <Texting type="label1 grey7" id="footer-all-rights-reserved"
              >Copyright © {{ new Date().getFullYear() }} Perkfection inc.<br />
              All rights reserved</Texting
            >
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import myServices from "../services/resource";
import Texting from "./Texting.vue";
import Images from "./MyImages";
import { mapState } from "vuex";
export default {
  components: {
    Images,
    Texting,
  },
  computed: {
    ...mapState({
      user: "user",
    }),
  },
  methods: {
    termsClick() {
      myServices.myAnalyticsEvents(
        this,
        "Homepage",
        "Terms & conditions click",
      );
      /*this.$ga.event('Homepage', 'Terms & conditions click');
      this.$mixpanel.track('Homepage', {
        action: 'Terms & conditions click',
      });*/
    },
    privacyClick() {
      myServices.myAnalyticsEvents(this, "Homepage", "Privacy Policy click");
      /*this.$ga.event('Homepage', 'Privacy Policy click');
      this.$mixpanel.track('Homepage', {
        action: 'Privacy Policy click',
      });*/
    },
    fbClick() {
      myServices.myAnalyticsEvents(
        this,
        "Homepage",
        "Social button click: Facebook",
      );
      /*this.$ga.event('Homepage', 'Social button click: Facebook');
      this.$mixpanel.track('Homepage', {
        action: 'Social button click: Facebook',
      });*/
    },
    twClick() {
      myServices.myAnalyticsEvents(
        this,
        "Homepage",
        "Social button click: Twitter",
      );
      /*this.$ga.event('Homepage', 'Social button click: Twitter');
      this.$mixpanel.track('Homepage', {
        action: 'Social button click: Twitter',
      });*/
    },
    liClick() {
      myServices.myAnalyticsEvents(
        this,
        "Homepage",
        "Social button click: LinkedIn",
      );
      /*this.$ga.event('Homepage', 'Social button click: LinkedIn');
      this.$mixpanel.track('Homepage', {
        action: 'Social button click: LinkedIn',
      });*/
    },
    getUrl() {
      return process.env.VUE_APP_BASE_URL;
    },
  },
};
</script>

<style scoped>
.logo {
  opacity: 0.8;
}
.logo:hover {
  opacity: 1;
}
.logo:hover {
  opacity: 1;
}

.user-footer {
  padding: 0;
  background-color: #f7f7f7;
  border-top: 1px solid #e5e5e5;
}
.footer-home {
  height: 112px !important;
}
.social-icons-mobile {
  display: none;
}
@media only screen and (max-width: 650px) {
  .footer-home {
    height: 164px !important;
  }
  .footer-texts {
    width: 100%;
  }
  .social-icons {
    display: none;
  }
  .social-icons-mobile {
    display: flex;
  }
}
@media only screen and (max-width: 599px) {
  .footer-home {
    height: 235px !important;
  }
}
</style>
