import { helpers } from "vuelidate/lib/validators";

export const strength = (value) => {
  if (!helpers.req(value)) return true;

  const allowFormat1 = /[a-z]/;
  const allowFormat2 = /\d/;
  const allowFormat3 = /[A-Z]/;

  return (
    allowFormat1.test(value) &&
    allowFormat2.test(value) &&
    allowFormat3.test(value)
  );
};
