<template>
  <img
    :src="
      this.name.includes('https')
        ? this.name
        : require('../assets/images/' + this.name)
    "
    :id="id"
    :width="width ? (width.includes('%') ? width : width + 'px') : 'auto'"
    :height="height ? (height.includes('%') ? height : height + 'px') : 'auto'"
    :alt="alt ? alt : name"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    alt: {
      type: String,
    },
    id: {
      type: String,
    },
  },
};
</script>
