<template>
  <!--      <button v-bind:type="`${b_type}`"  @click="handleClick" v-bind:class="`s-${type} s-${size} s-btn`"><span>{{ label }}</span></button>-->
  <button
    :id="id"
    :style="{
      backgroundColor: disabled_button ? '#858585' : '',
      pointerEvents: disabled_button ? 'none' : '',
    }"
    v-bind:class="`s-${type} s-${size} ${custom} s-btn`"
    v-bind:type="`${b_type}`"
    @click="handleClick()"
  >
    <Texting :type="texting" class="gilroy-medium">
      <slot />
    </Texting>
  </button>
</template>

<script>
import Texting from "./Texting";

export default {
  components: { Texting },
  props: {
    size: {
      type: String,
      default: "normal",
    },
    handleClick: {
      type: Function,
    },
    type: {
      type: String,
      default: "default",
    },
    b_type: {
      type: String,
      default: "button",
    },
    custom: {
      type: String,
      default: "",
    },
    texting: {
      type: String,
      default: "p2bold mywhite",
    },
    disabled_button: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
  },
};
</script>
